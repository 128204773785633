// 一套默认主题以及多套静态主题
export const themes = {
    default: {
      themeColor: `${144},${85},${255}`,
      themeTextColor: `${110},${106},${106}`,
    },
    dark: {
      themeColor: `${0},${0},${0}`,
      themeTextColor: `${0},${0},${0}`,
    },
    darkBlue: {
      themeColor: `${64},${158},${255}`,
      themeTextColor: `${110},${106},${106}`,
    },
    blackishGreen: {
      themeColor: `${6},${188},${88}`,
      themeTextColor: `${110},${106},${106}`,
    },
    orangeRed:{
      themeColor: `${245},${88},${26}`,
      themeTextColor: `${110},${106},${106}`,
    },
    Cyan:{
      themeColor: `${53},${210},${187}`,
      themeTextColor: `${110},${106},${106}`,
    },
    deepYellow:{
      themeColor: `${253},${217},${34}`,
      themeTextColor: `${110},${106},${106}`,
    },
  };