<template>
  <div class="external">
    <el-container class="home_container">
      <!-- 头部区域 -->
      <el-header height="70px">
        <div class="header_container">
          <div class="leftbox">
            <img src="@/assets/images/logo.png" class="logo" />
          </div>
          <div class="rightbox">
            <Theme></Theme>

            <div class="full_screen" @click="changeFullScreen">
                 <el-tooltip :content="fullscreen?'退出全屏模式':'开启全屏模式'"  placement="top">
                    <i class="haowu" :class="fullscreen?'hw-tuichuquanping':'hw-quanping'" style="font-size:20px;"></i>
                </el-tooltip>
            </div>

            <div class="noticebox">
              <el-dropdown trigger="click" placement="top-end" :hide-on-click="false">
                <el-badge class="item" :value="notificationBarInfo.unread">
                  <i class="haowu hw-xiaoxi" style="font-size:23px;color:#ffffff;cursor: pointer;"></i>
                </el-badge>
                <el-dropdown-menu slot="dropdown" size="medium" class="notice_menu">
                  <div class="notice_top">
                    <div class="left_title">公告栏</div>
                    <div class="right_msg">View All Notifications</div>
                  </div>

                  <div class="notice_middle">
                    <el-dropdown-item v-for="item in notificationBarInfo.notice" :key="item.id" :divided="item.divided">
                      <div class="notice_content" @click="getNotificationContent(item.id)"> 
                        <div class="content_left">
                          <div class="notice_avatar avatar_a"  v-if="item.type==1">
                            <i class="haowu hw-tuijian" style="font-size:22px;font-weight:600;margin-left:5px"></i>
                            <div class="dot" v-if="item.state"></div>
                          </div>

                           <div class="notice_avatar avatar_b" v-else-if="item.type==2">
                            <i class="haowu hw-reduxinxi" style="font-size:22px;font-weight:600;margin-left:5px" ></i>
                            <div class="dot" v-if="item.state"></div>
                          </div>

                           <div class="notice_avatar avatar_c" v-else-if="item.type==3">                         
                            <i class="haowu hw-wenjianxinxi" style="font-size:22px;font-weight:600;margin-left:5px"></i>
                            <div class="dot" v-if="item.state"></div>
                          </div>

                           <div class="notice_avatar avatar_d" v-else-if="item.type==4">
                            <i class="haowu hw-xiaoxi_01" style="font-size:22px;font-weight:600;margin-left:5px"></i>
                            <div class="dot" v-if="item.state"></div>
                          </div>

                           <div class="notice_avatar avatar_e" v-else>
                            <i class="haowu hw-xitonggonggao_01" style="font-size:22px;font-weight:600;margin-left:5px"></i>
                            <div class="dot" v-if="item.state"></div>
                          </div>
                        </div>
                        <div class="content_right">
                          <div class="nr">
                            <div class="main">{{item.title}}</div>
                            <div class="time">{{item.time}}</div>
                          </div>
                          <!-- <div class="r_bottom">{{item.subtitle}}</div> -->
                        </div>
                      </div>
                    </el-dropdown-item>
                  </div>

                  <div class="notice_bottom">
                    <span class="b_msg">将全部信息标记为</span>
                    <el-button type="primary" class="haowu hw-queren read" size="mini" @click="allRead">已读</el-button>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <div class="account_balance">
              <i class="haowu hw-yue_03" style="font-size:28px;margin-right:3px"></i>
              <span class="moneybox">
                余额:
                <b class="money">{{headerInfo.money}}</b>元
              </span>
            </div>

            <div class="userbox">
              <el-avatar :size="30" :src="headerInfo.photo"></el-avatar>

              <el-dropdown :hide-on-click="false">
                <span class="el-dropdown-link">
                  {{headerInfo.mot}}
                  <i class="el-icon-caret-bottom"></i>
                </span>
                <el-dropdown-menu slot="dropdown" size="medium">
                  <!-- <el-dropdown-item>个人中心</el-dropdown-item> -->
                  <el-dropdown-item @click.native="logout" class="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </el-header>
      <!-- 主体区域 -->
      <el-container>
        <!-- 侧边栏区域 -->
        <el-aside :width="isCollapse?'64px':'200px'">
          <!-- 侧边栏菜单区域 -->
          <!-- background-color="#333744" 
          active-text-color="#ffd04b"-->

          <el-menu
            :collapse="isCollapse"
            :collapse-transition="false"
            :unique-opened="true"
            :router="true"
            :default-active="activeIndex"
          >
            <!-- <div class="toggle-button" @click="toggleCollapse">
            <i class="haowu" :class="[isCollapse?'hw-zhedieyou':'hw-zhediezuo']"></i>
            </div>-->

            <template v-for="item in menuList">
              <!--存在二级菜单-->
              <template v-if="item.children.length>0">
                <el-submenu :index="item.id+''" :key="item.id">
                  <!-- 一级菜单的模板区域 -->
                  <template slot="title">
                    <i class="haowu" :class="item.icons"></i>
                    <span class="wz">{{item.authName}}</span>
                  </template>
                  <!-- 二级菜单 -->
                  <el-menu-item
                    v-for="subItem in item.children"
                    :key="subItem.id"
                    :index="'/'+subItem.path"
                  >
                    <template slot="title">
                      <i class="haowu" :class="subItem.icons"></i>
                      <span class="wz">{{subItem.authName}}</span>
                    </template>
                  </el-menu-item>
                </el-submenu>
              </template>

              <!--不存在二级菜单-->
              <el-menu-item :index="'/'+item.path" :key="item.id" v-else>
                <i class="haowu" :class="item.icons"></i>
                <span slot="title" class="wz">{{item.authName}}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </el-aside>
        <!-- 右侧主体区域 -->
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>

    <!-- 通知对话框 -->
    <el-dialog :title="notificationContent.push" :visible.sync="noticeDialogVisible" width="50%">
          <div class="notice_msg">
                 <div class="top">
                      <div class="title">{{notificationContent.title}}</div>
                      <!-- <p class="subtitle">功能模块优化</p> -->
                 </div>
                 <div class="middle message">
                      <p class="content" v-html="notificationContent.content"></p>
                 </div>
                 <div class="bottom">
                      <!-- <p class="publisher">发布人：超级管理员</p> -->
                      <p class="time">{{notificationContent.time}}</p>
                      <span slot="footer" class="dialog-footer">
                            <el-button type="primary" class="haowu hw-queren" @click="noticeDialogVisible = false">确定</el-button>
                     </span>
                 </div>
          </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapState } from "vuex";
import Theme from './theme/theme'
export default {
  data() {
    return {
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      // circleUrl:'@/assets/images/avatar.jpg',
      // 是否折叠
      isCollapse: false,
      activeIndex: "/home",
      //控制系统通知对话框的显示与隐藏
      noticeDialogVisible:false,
      //通知信息列表
      noticeList:[
           {
             id:1,
             type:1,
             divided:false,
             title:'New Customers',
             subtitle:'5 new user registered',
             time:'14 Sec ago'
           },
           {
             id:2,
             type:2,
             divided:true,
             title:'TNew Orders',
             subtitle:'You have recived new orders',
             time:'2 min ago'
           },
           {
             id:3,
             type:3,
             divided:true,
             title:'24 PDF File',
             subtitle:'The pdf files generated',
             time:'19 min ago'
           },
           {
             id:4,
             type:4,
             divided:true,
             title:'Time Response',
             subtitle:'5.1 min avarage time response',
             time:'28 min ago'
           },
           {
             id:5,
             type:5,
             divided:true,
             title:'New Product Approved',
             subtitle:'Your new product has approved',
             time:'2 hrs ago'
           },
           {
             id:6,
             type:1,
             divided:true,
             title:'New Customers',
             subtitle:'5 new user registered',
             time:'14 Sec ago'
           },
           {
             id:7,
             type:2,
             divided:true,
             title:'TNew Orders',
             subtitle:'You have recived new orders',
             time:'2 min ago'
           },
           {
             id:8,
             type:3,
             divided:true,
             title:'24 PDF File',
             subtitle:'The pdf files generated',
             time:'19 min ago'
           },
           {
             id:9,
             type:4,
             divided:true,
             title:'Time Response',
             subtitle:'5.1 min avarage time response',
             time:'28 min ago'
           },
           {
             id:10,
             type:5,
             divided:true,
             title:'New Product Approved',
             subtitle:'Your new product has approved',
             time:'2 hrs ago'
           }
      ],
      //是否开启全屏模式
      fullscreen:false,
      //通知图片预览集合
      PreviewImageList:[]
    };
  },
  methods: {
     //获取头部栏信息
     getHeaderInfo(){
         this.$store.dispatch('getHeaderInfo')
     },
     //获取左侧菜单栏数据
     getMenuList(){
         this.$store.dispatch("getMenuList");
     },
     //是否开启全屏模式
     changeFullScreen(){
            let element = document.documentElement;
             if (this.fullscreen) {
                 if (document.exitFullscreen) {
                     document.exitFullscreen();
                 } else if (document.webkitCancelFullScreen) {
                     document.webkitCancelFullScreen();
                 } else if (document.mozCancelFullScreen) {
                     document.mozCancelFullScreen();
                 } else if (document.msExitFullscreen) {
                     document.msExitFullscreen();
                 }
             } else {
                 if (element.requestFullscreen) {
                     element.requestFullscreen();
                 } else if (element.webkitRequestFullScreen) {
                     element.webkitRequestFullScreen();
                 } else if (element.mozRequestFullScreen) {
                     element.mozRequestFullScreen();
                 } else if (element.msRequestFullscreen) {
                     // IE11
                     element.msRequestFullscreen();
                 }
             }
            //  this.fullscreen = !this.fullscreen;
     },
     //获取通知栏列表信息
     getNotificationBarInfo(){
        this.$store.dispatch('getNotificationBarInfo')
     },
     //获取通知内容信息并且修改状态为已读
     async getNotificationContent(id){
         try{
             await this.$store.dispatch('getNotificationContent',id)
             this.noticeDialogVisible = true
             this.getNotificationBarInfo()
         }catch(error){
            this.$message.error(error.message)
         }
     },
     //将所有通知设置为已读状态
     async allRead(){
          try{
              await this.$store.dispatch('allRead') 
              this.getNotificationBarInfo()
          }catch(error){
             this.$message.error(error.message)
          }        
     },
     //退出登录
     logout(){
           // 打开提示弹窗
          this.$confirm('是否确认退出登录?', '提示', {
               closeOnClickModal:false,
               distinguishCancelAndClose: true,
               confirmButtonText: '退出',
               cancelButtonText: '取消',
               type: 'warning',
               center: true
           })
          .then(async () => {
               try{
                    await this.$store.dispatch('logout')
                    this.$message.success("退出成功！");
                    this.$router.replace('/login')
               }catch(error){
                    this.$message.error(error.message);
               }
          })
          .catch(action => {
          });
     }
  },
  components:{
      Theme
  },
  computed: {
    ...mapState({
      headerInfo:state => state.home.headerInfo,
      menuList: state => state.home.menuList,
      notificationBarInfo:state => state.home.notificationBarInfo,
      notificationContent:state => state.home.notificationContent
    })
  },
  created() {
      this.getHeaderInfo()
      this.getMenuList()
      this.getNotificationBarInfo()
      
      if (this.$route.path == "/datamonitoring") {
        this.activeIndex == "/home";
      } else {
        this.activeIndex = this.$route.path;
      }
  },
  mounted() {
    //折叠
    this.$bus.$on("toggleCollapse", val => {
      if (val) {
        this.isCollapse = true;
      } else {
        this.isCollapse = false;
      }
    });


   //监听ESC与F11
   window.addEventListener('keydown', function(event) {
      //禁掉F11的全屏的默认事件,不会禁止F11的退出全屏
      const e = event || window.event
      if (e && e.keyCode === 122) {
        e.preventDefault()
      }
    })
    document.addEventListener('fullscreenchange', () => {
        this.fullscreen = !this.fullscreen;
        //这些监听,可以监听到esc按键按下导致的全屏变化,但是监听不到F11的打开全屏的变化,会自动调用fullScreenEvent方法
    })
    document.addEventListener('mozfullscreenchange', () => {
        this.fullscreen = !this.fullscreen;
    })
    document.addEventListener('webkitfullscreenchange', () => {
        this.fullscreen = !this.fullscreen;
    })
    document.addEventListener('msfullscreenchange', () => {
        this.fullscreen = !this.fullscreen;
    })

  },
  watch: {
    $route() {
      if (this.$route.path == "/datamonitoring") {
        this.activeIndex == "/home";
      } else if (this.$route.path == "/fillincommodityinfo") {
        this.activeIndex == "/publish";
      } else {
        this.activeIndex = this.$route.path;
      }
    },
    


  }
};
</script>

<style lang="less" scoped>
// @assets: "~@/assets";
// @import "@{assets}/css/theme.less";
.external {
  height: 100%;
  .home_container {
    height: 100%;
    .header_container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .leftbox {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .logo {
          width: 224px;
          height: 56px;
          margin-left: 10px;
        }
      }
      .rightbox {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 30px;
        .themes{
           
        }
        .full_screen{
           cursor: pointer;
           margin-left:15px;
        }
        .noticebox {
          margin: 0 20px 0 15px;
        }
        .account_balance {
          display: flex;
          justify-content: center;
          align-items: center;
          .moneybox {
            font-size: 16px;
            font-weight: 500;
            .money {
              font-size: 18px;
              color: #ffffff;
              font-weight: 800;
            }
          }
        }
        .userbox {
          margin-left: 13px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .el-avatar {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.notice_menu {
  width: 350px;
  height: auto;
  .notice_top {
    height: 50px;
    padding: 0 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(@themeColor, 0.02);
    .left_title {
      font-size: 15px;
      color: #1c1b1b;
      font-weight: 600;
    }
    .right_msg {
      font-size: 12px;
      color: #585858;
      margin-bottom: 0;
    }
  }
  .notice_middle {
    height: 340px;
    border-top: 1px solid #f8f8f8;
    border-bottom: 1px solid #f8f8f8;
    overflow-y: scroll;
    .notice_content {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .content_left {
        width: 60px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .notice_avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .dot{
              width:8px;
              height:8px;
              border-radius:50%;
              background-color:#ff3b30;
              position: absolute;
              top:3px;
              right:5px;
          }
        }
      }
      .content_right {
        width: 240px;
        height: 100%;
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .nr {
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .main {
             width: 160px;
             line-height:20px;
             font-size: 14px;
             font-size:500;
             overflow: hidden;
             text-overflow: ellipsis;
             display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
          .time {
             font-size: 12px;
             color: #919191;
          }
        }
      }
    }
  }
  .notice_bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    .b_msg {
      color: #605d5d;
      font-size:16px;
      font-weight:500;
      margin-right:3px;
    }
    .clear {
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
  }
}

.avatar_a {
  background: #e4efff;
  color: #418dfd;
}
.avatar_b {
  background: #fee5e7;
  color: #f41127;
}
.avatar_c {
  background: #e6f5e5;
  color: #24a51a;
}
.avatar_d {
  background: #fff8e4;
  color: #ffc61d;
}
.avatar_e {
  background: #d3f5fc;
  color: #14ccf0;
}

.logout{
   color:rgba(@themeColor, 1),;
}

.notice_msg{
      height:auto;
      padding:0 20px;
      .top{
           width:100%;
           height:auto;
           .title{
               text-align: center;
               font-size:25px;
               font-weight:600;
           }
           .subtitle{
               text-align:center;
               font-size:16px;
               margin-top:10px;
           }
      }
      .middle{
               width:100%;
               height:420px;
               margin-top:10px;
               overflow: auto;
               padding-bottom:50px;
               .content{
                    font-size:15px;
                    text-indent:30px;
                    line-height:25px;
               }
               .img{
                    width:50%;
                    height:50%;
               }
      } 
      .bottom{
           height:60px;
           margin-top:20px;
           padding:0 50px 0;
           .publisher{
                text-align:right;
                font-size:18px;
                font-weight:500;
           }
           .time{
                text-align:right;
                font-size:16px;
                font-weight:600;
                margin-top:10px;
           }
           .dialog-footer{
               display: flex;
               justify-content: center;
               align-items: center;
           }
      }
}

.el-header {
  background-color: #373d41;
  background-image: linear-gradient(
    to bottom right,
    rgba(@themeColor, 1),
    rgba(@themeColor, 0.7),
  );
  background-color: rgba(@themeColor, 1);
  display: flex;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
}
.el-aside {
  border-right: 2px solid rgba(@themeColor, 1);
  overflow: hidden;
  .el-menu {
    border-right: none;
    background: none;
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  font-weight: 800;
}

.el-main {
  background-color: #eaedf1;
  padding: 0;
}

.wz {
  margin-left: 5px;
}

.el-menu-item {
  color: @themeTextColor;
}
/deep/ .el-submenu__title {
  color: @themeTextColor;

}

.el-menu-item.is-active {
   color:rgba(@themeColor, 1) !important;
}

/deep/ .el-submenu__title:hover {
  background-color:rgba(@themeColor, 0.03) !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color:rgba(@themeColor, 0.03);
}

/deep/ .el-submenu.is-active .el-submenu__title {
  border-bottom-color: rgba(@themeColor,1);
}

.el-dropdown-menu {
  padding: 0 0 10px 0;
}

.el-dropdown-menu__item {
  padding: 5px 20px 5px;
}

.el-dropdown-menu__item--divided {
  margin-top: 0;
  padding: 5px 20px 5px;
}

.el-dropdown-menu__item--divided:before {
  height: 0px;
}

/deep/ .el-dialog__body{
    padding-top:10px !important;
}

/*自定义滚动条*/
// .scrollbar{
//       background-color:#F5F5F5;
//       height:300px;
//       width: 65px;
//       margin: 100px auto;
//       overflow-y: scroll;
// }

// .overflow {
//      height: 450px;
// }

/*滚动条区域*/
.notice_middle::-webkit-scrollbar {
  width: 10px;
  background-color: #fff;
}

/*滚动条*/
.notice_middle::-webkit-scrollbar-thumb {
  background-color:rgba(@themeColor,1);
  border-radius: 10px;
  height: 50px;
}

/*滚动条外层轨道*/
.notice_middle::-webkit-scrollbar-track {
  // -webkit-box-shadow:inset 0 0 1px;
  background-color:rgba(@themeColor,0.05);
  border-radius: 10px;
}


/*滚动条区域*/
.message::-webkit-scrollbar {
  width: 10px;
  background-color: #fff;
}

/*滚动条*/
.message::-webkit-scrollbar-thumb {
  background-color: rgba(@themeColor,1);
  border-radius: 10px;
  height: 50px;
}

/*滚动条外层轨道*/
.message::-webkit-scrollbar-track {
  // -webkit-box-shadow:inset 0 0 1px;
  background-color:rgba(@themeColor,0.05);
  border-radius: 10px;
}
</style>