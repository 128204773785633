<template>
     <div class="theme">
            <el-dropdown trigger="click" placement="top-end" :hide-on-click="false">  
                <!-- <i class="haowu hw-theme" style="font-size:23px;color:#ffffff;cursor: pointer;"></i> -->
                <img src="@/assets/images/theme_03.png" class="theme_img">
                <el-dropdown-menu slot="dropdown">
                       <div class="content">
                            <div class="theme_item" :style="{border:(themeIndex==index? `2px solid ${item.color}`:'')}" v-for="(item,index) in themes" :key="item.id" @click="changeTheme(index,item.name)">
                               <div class="item_top" :style="{background:item.color}"></div>
                               <div class="item_bottom">
                                   <div class="b_left" :style="{'border-right': `2px solid ${item.color}`}"></div>
                                   <div class="b_right">
                                       <div class="breadcrumb"></div>
                                       <div class="main"></div>
                                   </div>
                               </div>
                            </div>

                         <div class="theme_item color_picker">
                               <el-color-picker v-model="color" show-alpha :predefine="predefineColors" @change="customTheme"></el-color-picker>
                         </div>
                     </div>
                </el-dropdown-menu>
            </el-dropdown>
     </div>
</template>

<script>
import { setTheme } from "@/theme/theme";
export default {
    data(){
        return{
               themes:[
                   {
                       id:1,
                       name:'default',
                       color:"rgba(144,85,255,1)",
                       textColor:"rgba(110,106,106,1)",
                   },
                   {
                       id:2,
                       name:'dark',
                       color:"rgba(0,0,0,1)",
                       textColor:"rgba(0,0,0,1)",
                   },
                   {
                       id:3,
                       name:'darkBlue',
                       color:"rgba(64,158,255,1)",
                       textColor:"rgba(110,106,106,1)",
                   },
                   {
                       id:4,
                       name:'blackishGreen',
                       color:"rgba(6,188,88,1)",
                       textColor:"rgba(110,106,106,1)",
                   },
                   {
                       id:5,
                       name:'orangeRed',
                       color:"rgba(245,88,26,1)",
                       textColor:"rgba(110,106,106,1)",
                   },
                   {
                       id:6,
                       name:'Cyan',
                       color:"rgba(53,210,187,1)",
                       textColor:"rgba(110,106,106,1)",
                   },
                   {
                       id:7,
                       name:'deepYellow',
                       color:"rgba(253,217,34,1)",
                       textColor:"rgba(110,106,106,1)",
                   }
               ],
               themeIndex:0,
               color: 'rgba(255, 69, 0, 0.68)',
               predefineColors: [
                 '#ff4500',
                 '#ff8c00',
                 '#ffd700',
                 '#90ee90',
                 '#00ced1',
                 '#1e90ff',
                 '#c71585',
                 'rgba(255, 69, 0, 0.68)',
                 'rgb(255, 120, 0)',
                 'hsv(51, 100, 98)',
                 'hsva(120, 40, 94, 0.5)',
                 'hsl(181, 100%, 37%)',
                 'hsla(209, 100%, 56%, 0.73)',
                 '#c7158577'
               ]
        }
    },
    methods:{
        //改变主题
        changeTheme(index,name){
            this.themeIndex = index
            setTheme(name);
        },

        init() {
              const themeColor = localStorage.getItem('themeColor')
              if(themeColor){//如果缓存里有直接拿
                   setTheme(); // 获取缓存主题  
              }else{
                   setTheme('default'); // 初始化未默认主题  
              }
        },


        //自定义主题
        customTheme(val){
            this.themeIndex = -1
            let r=this.rgbaNum(val,0)
            let g=this.rgbaNum(val,1)
            let b=this.rgbaNum(val,2)
            let a=this.rgbaNum(val,3)
            let newthemeColor = `${r},${g},${b}`;
            localStorage.setItem("themeColor", newthemeColor); // 将新的主题色存入本地
            setTheme();
        },

        //获取rgba的数值
        rgbaNum(rgba, index) {
            let val = rgba.match(/(\d(\.\d+)?)+/g);
            return val[index];
        }
    },
    mounted(){
        this.init(); // 初始化主题
    }

}
</script>

<style lang="less" scoped>
.theme_img{
    width:33px;
    height:27px;
}
.content{
     width:360px;
     height:auto;
     padding:10px;
     display:flex;
     justify-content: space-around;
     flex-wrap: wrap;
     .theme_item{
         width:150px;
         height:120px;
         margin-bottom: 20px;
         border:2px solid #d3d1d1;
         padding:2px;
         .item_top{
            width:100%;
            height:30px;
            // background:#9055FF;
         }
         .item_bottom{
            width:100%;
            height:90px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .b_left{
                width:30px;
                height:100%;
                // border-right:2px solid #9055FF;
                background: #f1efef;
            }
            .b_right{
                width:120px;
                height:100%;
                .breadcrumb{
                     width:100%;
                     height:10px;
                     background: #f1efef;
                }
                .main{
                     height:70px;
                     background: #f1efef;
                     margin:5px 8px;
                }
            }
         }
     }
     .color_picker{
         display: flex;
         justify-content: center;
         align-items: center;
     }
}
.select_theme{
     border:1px solid #ff0000;
}
</style>